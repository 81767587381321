import React, { useState, useEffect } from 'react';
import '../css/display-view.css';

const questions = [
  { question: "I am a Catholic", subtitle: "Ask Me Questions." },
  { question: "I am Pro-life", subtitle: "Ask Me Questions." },
  { question: "The Catholic Church is right on everything", subtitle: "Change My Mind" },
  { question: "Protestantism is false", subtitle: "Change My Mind." },
  { question: "The Bible is NOT the only infallible rule of faith", subtitle: "Change My Mind." },
  { question: "God Exists", subtitle: "Change My Mind." },
  { question: "Christ is LORD", subtitle: "Change My Mind." },
  { question: "IVF is Evil", subtitle: "Change My Mind." },
  { question: "You Should Pray to Mary", subtitle: "Change My Mind." },
];

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

const DisplayView = () => {
  const [node, setNode] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const channel = new BroadcastChannel('node-updates');
    channel.onmessage = (event) => {
      if (event.data.type === 'update') {
        setNode(event.data.node);
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % questions.length);
    }, 10000); // Rotate every 10 seconds

    return () => clearInterval(interval);
  }, []);

  const hasBody = node?.body && node.body.trim() !== '';

  return (
    <div className="display-view">
      {!hasBody ? (
        <div className="default-slide">
          <h1 className="slide-question">{toTitleCase(questions[currentIndex].question)}</h1>
          <p className="slide-subtitle">{toTitleCase(questions[currentIndex].subtitle)}</p>
        </div>
      ) : (
        <div className="content-slide">
          <h1 className="slide-title">{toTitleCase(node.title)}</h1>
          <div
            className="slide-body"
            dangerouslySetInnerHTML={{ __html: node.body }}
          />
        </div>
      )}
    </div>
  );
};

export default DisplayView;
